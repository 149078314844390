<template>
    <v-app style="max-width:425px; padding: 0 20px;"  >
        <div class="visbyRoundFont" style="padding: 0px 0px;">
         
            <div style="display: flex; flex-direction: column; align-items: flex-start; margin-bottom: 16px; margin-top: 16px;">
                <div style="color: #6b6b6b;">Just One More Step...</div>
                <div style="font-size: 20.6px; font-weight: 600; width: 100%; line-height: 32.4px;display: flex; align-items: center; justify-content: flex-start; text-align: left;"><span>How Young Are You?</span>
                    <img  src="https://s3iconimages.mymedicine.com.mm/gift.gif" style="height: 30px; width: 30px;"/>
                </div>
            </div>
            <div class="wheel-picker" style="margin-top: 50px;">
      <div class="picker-column">
        <div class="picker-item" v-for="(month, index) in months" :key="index" :class="{ selected: selectedMonth === index }" @click="selectMonth(index)">
          {{ month }}
        </div>
      </div>
      <div class="picker-column">
        <div class="picker-item" v-for="(day, index) in days" :key="index" :class="{ selected: selectedDay === day }" @click="selectDay(day)">
          {{ day }}
        </div>
      </div>
      <div class="picker-column">
        <div class="picker-item" v-for="(year, index) in years" :key="index" :class="{ selected: selectedYear === year }" @click="selectYear(year)">
          {{ year }}
        </div>
      </div>
    </div>
            <div style="display: flex; flex-direction: column; gap: 10px; margin-top: 50px;">
                <v-btn  elevation="0" block outlined  class="custom-button"  @click="emitForSignUpNoDOB" style=" border: 1px solid #efefef; height: 48px;"><span style="font-weight: 500;" >I Prefer not to say</span></v-btn>
                <v-btn elevation="0" block color="#4aaaf2" class="custom-button" @click="emitForSingUp"  style="height: 48px;"><span style="color: #fff; font-weight: 500;" >Continue</span></v-btn>
            </div>
        </div>
    </v-app>
</template>

<script>

import axios from "axios";
export default {
  data() {
    return {
        selectedGender: '',    









        otpValue: '',
      phone_number: "06307970594",
      password: "",
      auth_error_enable: false,
      error_enable: false,
      showPwd:false,
      LanguageList: [{"item-text":"English","item-value":"en","image":"https://s3iconimages.mymedicine.com.mm/usa_flag.svg"},{"item-text":"Burmese","item-value":"mm","image": "https://s3iconimages.mymedicine.com.mm/myanmar_flag.svg"}],
      Language: "",
      passwordRules: [v => !!v || "Password is required"],
      mobileNumberRules: [
                        v => !!v || 'Mobile Number is required',
                        v => (v && v.length <= 11) || 'Mobile Number should be between 7 to 11 numbers',
                        v => v > 0 || 'Mobile number cannot be negative',
                        ],
      mobileNumberRulesMM: [
                        v => !!v || 'ဖုန်းနံပါတ်ထည့်သွင်းရန် လိုအပ်ပါသည်။',
                        v => (v && v.length <= 11) || 'ဖုန်းနံပါတ်၏ ဂဏန်းအရေအတွက်မှာ ၇ လုံးမှ ၁၁ လုံးဖြစ်ရပါမည်။',
                        v => v > 0 || 'Mobile number cannot be negative',
                        ],
      errorMessage : "",
      phoneNumberError: false,
      customerUnauthorized : false,
      invalidCredentials : false,
      isLoginClicked : false,
      isLoading : false,
      selectedMonth: 0,
      selectedDay: 1,
      selectedYear: 2024,
      months: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      days: Array.from({ length: 31 }, (v, k) => k + 1),
      years: Array.from({ length: 50 }, (v, k) => 2024 - k),
    };
  },
  methods: {
    selectMonth(index) {
      this.selectedMonth = index;
    },
    selectDay(day) {
      this.selectedDay = day;
    },
    selectYear(year) {
      this.selectedYear = year;
    },
    async emitForSingUp() {
        try {
          this.$emit('emitSignUp', { month: this.selectedMonth, year: this.selectedYear, day: this.selectedDay, noDOB: false, })
        } catch (error) {
            console.log(error);
        }
    },
    emitForSignUpNoDOB() {
        try {
          this.$emit('emitSignUp', { noDOB: true })
        } catch (error) {
            console.log(error);
        }
    },


    getGenderBoxClass(value) {
        return value == this.selectedGender ? 'genderBoxActive' : 'genderBoxInactive'
    },




    disableLoading(){
      this.phoneNumberError = false;
      this.isLoading = false;
      this.isLoginClicked = false;
    },
    redirectToLandingPage(){
      this.$router.go(-1);
    },
    changeLanguage(){
      this.$i18n.locale = this.Language;
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    },
    Login() {
      this.isLoginClicked = true;
      this.isLoading = true;
      let encryptedText = this.CryptoJS.AES.encrypt(this.password,process.env.VUE_APP_ENCRYPTION_KEY).toString()
      var customerLoginData = {
        mobileNumber: this.phone_number,
        token: "",
        typeOfUser: "CUSTOMER",
        password : encryptedText
      };
      axios
      .post(
        process.env.VUE_APP_BACKEND_URL + "/customerSignIn",
        customerLoginData
        )
      .then((customerLoginData) => {
        this.isLoading = false

        this.$cookies.set("customerToken", customerLoginData.data.token,"1h");
        this.$cookies.set("access",customerLoginData.data.accessToken);


          this.$router.push({
            path: "/customer/teleconsultationHome",
          });
        })
      .catch((customerLoginException) => {
        this.isLoginClicked = false
        this.isLoading = false
       if(customerLoginException.response.status == 400 || customerLoginException.response.status == 303 || customerLoginException.response.status == 401){
             this.auth_error_enable = true;
             if(this.Language == 'en'){
               this.errorMessage = "You do not have a registered account";
             }
             else{
               this.errorMessage="သင်အကောင့်မဖွင့်ရသေးပါ"
             }

             this.customerUnauthorized = true;
       }
       else if(customerLoginException.response.status == 306){
          this.auth_error_enable = true;
          if(this.Language == 'en'){
            this.errorMessage = "Invalid Credentials";
          }
          else{
            this.errorMessage = "Invalid Credentials"
          }

          this.invalidCredentials = true;

       }
      });
    },
    closeDialogError(){
      this.error_enable = false;
    },
    LoginWithOTP(){
      this.isLoginClicked = true;
      if(this.phone_number[0] == "0")
      {
        var otpBody = {
          phoneNumber: this.phone_number
        };
        axios.post(process.env.VUE_APP_BACKEND_URL+"/checkIfPhoneNumberExists",otpBody)
        .then((checkIfPhoneNumberExistsResponse) =>{
          this.isLoginClicked = false
          this.isLoading = false
          this.auth_error_enable = true;
          if(this.Language == 'en'){
             this.errorMessage = "You do not have a registered account";
          }
          else{
            this.errorMessage = "သင်အကောင့်မဖွင့်ရသေးပါ"
          }

          this.customerUnauthorized = true;
        })
        .catch((checkIfPhoneNumberExistsError) =>{
          if(checkIfPhoneNumberExistsError.response.status == 305){
            axios.post(process.env.VUE_APP_BACKEND_URL+"/sendOTP",otpBody)
            .then((sendOTPResponse) =>{
              this.$router.push({
                name: 'OTPLogin',
                params: {
                  'phone_number': this.phone_number
                }
              });
            })
            .catch((sendOTPError) =>{
              this.error_enable = true;
            });
          }
          else
          {
            this.error_enable = true;
          }
        });
      }
      else
      {
        this.phoneNumberError = true;
      }
    },
    closeDialogUnauthorized() {
      this.$router.push({
        path: "/signup",
      });
    },
    closeDialogInvalidCredentials(){
      this.invalidCredentials = false;
      this.auth_error_enable = false;
    }
  },
  mounted() {
    this.currentCustomer = this.$cookies.get("customerToken");
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.Language = this.$i18n.locale;
    var access = this.$cookies.get("access");
    if(this.currentCustomer){

        var verifyCustomerTokenData = {
            "token" : this.currentCustomer,
            "typeOfUser" : "CUSTOMER"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",verifyCustomerTokenData)
        .then((verifyCustomerTokenDataResponse) => {
          this.$router.push({
            path: "/customer/teleconsultationHome",
          });
        })
        .catch((verifyCustomerTokenDataError)=>{
          this.$cookies.remove("customerToken");
          this.$cookies.remove("access");
        });
    }
    else{
      this.$cookies.remove("customerToken");
      this.$cookies.remove("access");
    }
  },

};
</script>

<style scoped>

.genderBoxActive {
    height: 136px;
    width: 136px;
    background: red;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(244, 249, 254, 0.5) 0%, rgba(117, 186, 245, 0.5) 100%);
    font-weight: 500;
    color: #fff;
    cursor: pointer;
}

.genderBoxInactive {
    height: 136px;
    width: 136px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e2e3e3;
    cursor: pointer;
    font-weight: 500;

}


.wheel-picker {
  display: flex;
  justify-content: space-around;
  height: 200px;
  overflow: hidden; /* Hide the scrollbar */
  border: 1px solid rgb(228, 212, 212);
  border-radius: 20px;
  padding: 20px;
  align-items: center
}

.picker-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  overflow: auto;  /* Allow scrolling, but hide scrollbar */
  scroll-behavior: smooth;
  height: 150px;
}

.picker-column::-webkit-scrollbar {
  display: none; /* Hide scrollbar on Webkit browsers */
}

.picker-column {
  -ms-overflow-style: none;  /* Hide scrollbar on IE and Edge */
  scrollbar-width: none;     /* Hide scrollbar on Firefox */
}

.picker-item {
  font-size: 20px;
  padding: 10px 0;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.picker-item.selected {
  font-weight: bold;
  opacity: 1;
}





.custom-button {
  text-transform: none;
  border-radius: 8px;
  font-size: 18px; letter-spacing: 0;
}
.font_size_12_400 {
  font-size: 14px;
  font-weight: 400;
}
.mx-input {
  height: 40px !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
/* @media (min-width: 800px){
  .loginClass{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }
  .MobileNumber{
    margin-top:14px !important
  }
  } */
</style>
