<template>
    <v-app v-if="!newCustomerStep" style="max-width:425px; margin-left:auto; margin-right:auto"  >
      <div class="linearBox">
        <div style="padding: 16px;">
            <language-change-part />
          </div>
            <img src="https://s3iconimages.mymedicine.com.mm/auth.gif" alt="" style="height: 130px; width: 130px;">
        </div>
        
        <div class="visbyRoundFont" style="padding: 0px 20px; margin-top: 16px;;" >
            <div style="font-size: 30.6px; font-weight: 600; width: 100%; line-height: 32.4px; margin-bottom: 16px; display: flex; align-items: center; justify-content: center;"><span>Authenticate</span>
                <img  src="https://s3iconimages.mymedicine.com.mm/key.gif" style="height: 40px; margin-left: 5px;"/>

            </div>
            <div style="margin-bottom: 12px;">
                <div style="color: #898989; font-weight: 500;">Enter the code sent to</div>
                <div style="color: #2e2e2e; font-weight: 500;">{{  phone_number }}</div>
            </div>
            <v-otp-input
                v-model="otpValue"
                length="6"
                type="number"
            ></v-otp-input>
            <div style="height: 32px; background-color: #fef1f4; color: red;" v-if="showInvalidOTP">Invalid OTP</div>
            <div style="display: flex; justify-content: space-between; margin-bottom: 16px;">
                <div style="color: #4aaaf2; font-weight: 500; cursor: pointer" v-if="platform!='AYA'" @click="goBack">Change Number</div>
                <div v-if="timeRemaining>0" style="font-weight: 500;">Resend Code in <span style="color: #4aaaf2;">{{ timeRemaining }}s</span></div>
                <div v-else style="cursor: pointer; color: #4aaaf2;" @click="sendOTP">Resend Code</div>
            </div>
            <v-btn elevation="0" block color="#4aaaf2" style="height: 48px;" class="custom-button" @click="verifyOTP" :disabled="otpValue.length<6 || otpLoading" :loading="otpLoading"><span style="color: #fff; font-weight: 500;" >Confirm</span></v-btn>
        </div>
    </v-app>
    <v-app v-else style="max-width:425px; margin-left:auto; margin-right:auto"  >
        <div class="linearBox" v-if="!loadingFlow">
            <div style="padding: 16px;">
            <language-change-part />
          </div>
            <img :src="getImageSrcForBackground()" alt="" style="height: 130px; width: 130px;">
        </div>
        <new-customer-flow v-if="takeNameStep" @userNameContinueClicked="allowGenderFlow"/>
        <gender-selection-flow v-if="genderSelectionFlow" @emitForDOBStep="allowAgeFlow"/>
        <dob-selection-flow v-if="ageFlow"  @emitSignUp="signUP"/>
        <building-profile-component v-if="loadingFlow" />

        
    </v-app>
</template>

<script>

import axios from "axios";
import newCustomerFlow from './newCustomerFlow.vue';
import GenderSelectionFlow from './genderSelectionFlow.vue';
import LanguageChangePart from './languageChangePart.vue';
import DobSelectionFlow from './dobSelectionFlow.vue';
import BuildingProfileComponent from './buildingProfileComponent.vue';
export default {
  components: { newCustomerFlow, GenderSelectionFlow, LanguageChangePart, DobSelectionFlow, BuildingProfileComponent },
  data() {
    return {
      otpValue: '',
      newCustomerStep: false,
      takeNameStep: false,
      genderSelectionFlow: false,
      ageFlow: false,
      createToken: '',
      timeRemaining: 60,
      killIntervalValue: null,
      showInvalidOTP: false,
      otpLoading: false,
      platform: '',
      phone_number: "",
      isLoading : false,
      loadingFlow: false,
    };
  },
  _methods: {
    async resendOTP() {
      this.timeRemaining = 60;

      await this.sendOTP();
      this.killIntervalValue = setInterval(() => {
        if (this.timeRemaining == 0) {
          clearInterval(this.killIntervalValue);
          return;
        }
        this.timeRemaining = this.timeRemaining - 1;
      }, 1000);
    },
    goBack() {
      this.$router.go(-1);
    },
    getImageSrcForBackground() {
      if (this.takeNameStep) return 'https://s3iconimages.mymedicine.com.mm/talk.gif';
      if (this.genderSelectionFlow || this.ageFlow) return 'https://s3iconimages.mymedicine.com.mm/id-card.gif';
    },
    allowGenderFlow(value) {
      console.log(value);
      this.genderSelectionFlow = true;
      this.takeNameStep = false;
      this.customerName = value.name;
    },
    allowAgeFlow(value) {
      this.genderSelectionFlow = false;
      this.takeNameStep = false;
      this.ageFlow = true;
      this.customerGender = value.gender;
    },
    async sendOTP() {
      try {
        this.otpLoading = true;
        await axios.post(process.env.VUE_APP_BACKEND_URL+"/sendOTP",{
          phoneNumber: this.phone_number,
        });
        this.otpLoading = false;
        
        } catch(error) {
        console.log(error);
      }

    },
    async signUP(value) {
      try {
        // get all data 
        // name
        // gender
        // dob
        // call api
        console.log(value);
        if (value.noDOB == true) {
          value['dob'] = ''
        } else {
          value.month = value.month+1;
          if (value.month <= 9) {
            value.month = `0${value.month}`
          };
          if (value.day <= 9) {
            value.day = `0${value.day}`
          };
          value['dob'] = `${value.year}-${value.month}-${value.day}`
        }
        this.ageFlow = false;
        this.loadingFlow = true;
        let customerLoginData = await axios.post(process.env.VUE_APP_BACKEND_URL + '/customersignup', {
          customerName: this.customerName,
          phoneNumber: this.phone_number,
          gender: this.customerGender,
          dob: value.dob,
          email: '',
          nrc: '',
          token: this.createToken
        });
        this.$cookies.set("customerToken", customerLoginData.data.token,"1w");
        let redirectionFlow = localStorage.getItem('redirectionFlow')
        if (redirectionFlow) {
          localStorage.removeItem('redirectionFlow');
          let path = localStorage.getItem('redirectionURL');
          localStorage.removeItem('redirectionURL');
          this.$router.push({
          path: path
        });
        } else {
          let source= 'HOME';
          console.log(source)
          this.$router.push({
              name: 'ePharmacyOdooHome',
              params: { source: source }
          });
      }
      } catch (error) {
        console.log(error);
      }
    },
    async verifyOTP() {
      try {
        this.showInvalidOTP = false;
        let platform = this.platform;
        this.otpLoading = true;
        let customerLoginData = {
          otpProvided: this.otpValue,
          mobileNumber: this.phone_number,
          platform: platform || ''
        };
        let customerLoginResponse = await axios.post(process.env.VUE_APP_BACKEND_URL + "/customerSignIn", customerLoginData);
        if (!customerLoginResponse.data.newFlow) {
          this.$cookies.set("customerToken", customerLoginResponse.data.token, "1w");
          this.$cookies.set("access", customerLoginResponse.data.accessToken);
          let redirectionFlow = localStorage.getItem('redirectionFlow');
          if (redirectionFlow) {
            localStorage.removeItem('redirectionFlow');
            let path = localStorage.getItem('redirectionURL');
            localStorage.removeItem('redirectionURL');
            this.$router.push({
              path: path
            });
          } else {
            let source= 'HOME';
          console.log(source)
          this.$router.push({
              name: 'ePharmacyOdooHome',
              params: { source: source }
          });
          }
        } else {
          // redirect to signup page.
          this.newCustomerStep = true;
          this.takeNameStep = true;
          this.createToken = customerLoginResponse.data.token;
        }
      } catch (error) {
        if(error.response.data.message == "OTP Mismatch") {
          this.showInvalidOTP = true;
        };
        console.log(error);
      } finally {
        this.otpLoading = false;
      }
    },
  },
  get methods() {
    return this._methods;
  },
  set methods(value) {
    this._methods = value;
  },
  created() {
    console.log(this.$route.params);
    const phoneNumber = this.$route.params.phoneNumber;
    this.phone_number = phoneNumber;
    if(!this.phone_number) {
      this.$router.go(-1);
    }
  },
  mounted() {
    this.platform = localStorage.getItem('platform');
    this.currentCustomer = this.$cookies.get("customerToken");
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.Language = this.$i18n.locale;
    if(this.currentCustomer){

        var verifyCustomerTokenData = {
            "token" : this.currentCustomer,
            "typeOfUser" : "CUSTOMER"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",verifyCustomerTokenData)
        .then((verifyCustomerTokenDataResponse) => {
          let source= 'HOME';
          console.log(source)
          this.$router.push({
              name: 'ePharmacyOdooHome',
              params: { source: source }
          });
        })
        .catch((verifyCustomerTokenDataError)=>{
          this.$cookies.remove("customerToken");
          this.$cookies.remove("access");
        });
    }
    else{
      this.$cookies.remove("customerToken");
      this.$cookies.remove("access");
    }
    this.killIntervalValue = setInterval(() => {
        if(this.timeRemaining == 0) {
          clearInterval(this.killIntervalValue);
          return;      
          
        } 
        this.timeRemaining = this.timeRemaining - 1;  
    }, 1000);
  },

};
</script>

<style scoped>
.custom-button {
  text-transform: none;
  border-radius: 8px;
  font-size: 18px; letter-spacing: 0;
}
.font_size_12_400 {
  font-size: 14px;
  font-weight: 400;
}
.mx-input {
  height: 40px !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.linearBox {
    
    padding: 0 20px;
    padding-top: 10px;
    width: 100%;
    height: 250px;
    background: linear-gradient(to right , #d9f2f5, #ffd5de);
    /* background: url("https://s3iconimages.mymedicine.com.mm/linerBackground.png"); */
    /* background: linear-gradient(to bottom right, #d9f2f5, #fcd2db);    background-repeat: no-repeat; */
    background-size: cover;
    border-radius: 0 0 40px 40px;
    background-position: center;
}
.v-input >>> .v-text-field__details {
    display: none;
}
/* @media (min-width: 800px){
  .loginClass{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;
s
  }
  .MobileNumber{
    margin-top:14px !important
  }
  } */
</style>
